import React from 'react';
import type { FC } from 'react';
import { css } from '@emotion/react';
import { Link, graphql } from 'gatsby';
import { Layout } from '../components/layout';
// eslint-disable-next-line import/no-unresolved
import { CaseReportPageQuery } from 'graphql-types';
import { SEO } from '../components/seo';
import { GatsbyImage } from 'gatsby-plugin-image';

interface PageProps {
  data: CaseReportPageQuery;
}

const Page: FC<PageProps> = ({ data }) => (
  <Layout>
    <SEO
      title="症例報告"
      description="代表的な腫瘍疾患の症例をご報告いたします"
      keywords="症例報告"
    />
    <article
      id="clinical-examination"
      css={css`
        @media (max-width: 560px) {
          padding: 1rem !important; // TODO: index.scss撲滅 撲滅次第importantを削除する
        }
      `}
    >
      <h2>症例報告 - Case report -</h2>
      <div className="skew-border"></div>
      <ul css={caseReportIndex}>
        {data.allMarkdownRemark.edges.map(edge => {
          if (!edge.node.frontmatter?.slug) return null;

          const slug = edge.node.frontmatter.slug;
          const thumbnail =
            edge.node.frontmatter.thumbnail?.childImageSharp?.gatsbyImageData;

          return (
            <li key={slug}>
              <Link to={slug}>
                {thumbnail && (
                  <GatsbyImage image={thumbnail} alt="" css={thumbnailStyle} />
                )}
                <h2 css={reportTitle}>
                  {edge.node.frontmatter.title ?? '無題'}
                </h2>
                {edge.node.excerpt && <p>{edge.node.excerpt}</p>}
                {edge.node.frontmatter.date && (
                  <p css={reportDate}>{edge.node.frontmatter.date}</p>
                )}
              </Link>
            </li>
          );
        })}
      </ul>
    </article>
  </Layout>
);
export default Page;

const caseReportIndex = css`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 2rem;

  li {
    width: calc((100% - 2rem * 3) / 4);
    height: 420px;
    box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1);
    padding-bottom: 1rem;

    @media (max-width: 560px) {
      width: 100%;
    }

    a {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }

    p {
      padding: 1rem 1rem 0;
    }
  }
`;

const thumbnailStyle = css`
  width: 100%;
  height: 140px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const reportTitle = css`
  text-align: left !important; // TODO: index.scss撲滅 撲滅次第importantを削除する;
  margin: 0 !important; // TODO: index.scss撲滅 撲滅次第importantを削除する;
  padding: 1rem 1rem 0;
  height: 3rem;
  font-size: 1rem;

  :hover {
    text-decoration: underline;
  }
`;

const reportDate = css`
  margin-top: auto;
`;

export const query = graphql`
  query CaseReportPage {
    allMarkdownRemark(
      filter: { frontmatter: { slug: { regex: "^/reports/" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          excerpt(pruneLength: 110, truncate: true)
          frontmatter {
            date(formatString: "YYYY/MM/DD", locale: "ja-JP")
            slug
            title
            thumbnail {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
